<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <AppButton
        class="d-block m-2 ml-auto"
        variant="primary"
        @click="openActorForm()"
      >
        <span class="text-nowrap">
          {{ $t("add_new_actor") || "Add New Actor" }}
        </span>
      </AppButton>
      <b-overlay :show="isLoading" rounded>
        <b-table
          class="position-relative"
          :items="actors"
          hover
          striped
          responsive
          :fields="fields"
          show-empty
          :empty-text="
            $t('no_matching_records_found') || 'No matching records found'
          "
        >
          <template #cell(name)="data">
            <div class="text-nowrap">
              <feather-icon
                :icon="data.item.name"
                size="18"
                class="mr-50"
                :class="`text-${data.item.name}`"
              />
              <span class="align-text-top text-capitalize">
                {{ data.item.name }}
              </span>
            </div>
          </template>
          <template #cell(edit_delete)="data">
            <div class="d-flex flex-column actions">
              <AppButton
                variant="primary"
                class="mb-1"
                @click="openActorForm(data.item)"
              >
                {{ $t("edit") || "Edit" }}
              </AppButton>
              <AppButton
                variant="danger"
                @click="openDeletionWarning(data.item)"
              >
                {{ $t("delete") || "Delete" }}
              </AppButton>
            </div>
          </template>
        </b-table>
        <div v-if="actors && actors.length" class="m-2">
          <span class="text-muted">
            {{ $t("total_entries", { total: actors.length }) }}
          </span>
        </div>
      </b-overlay>
    </b-card>

    <ActorForm :id="'actor-form'" :actor="activeActor" @hide="hideActorForm" />

    <deleteWarning
      :show="isDeletionWarnShown"
      :is-loading="isDeleting"
      :action-label="$t('delete') || 'Delete'"
      :cancel-label="$t('cancel') || 'Cancel'"
      :message="$t('are_you_sure') || 'Are You Sure?'"
      @close="cancelDeletionHandler"
      @delete="deleteActor"
    />
  </div>
</template>

<script>
import { BCard, BTable, BOverlay } from "bootstrap-vue";

import ActorForm from "./partials/ActorForm.vue";
import deleteWarning from "@/components/UI/deleteWarning.vue";
import { AppButton } from "@/components/form/index";

export default {
  name: "Actors",
  components: {
    ActorForm,
    BCard,
    BTable,
    BOverlay,
    deleteWarning,
    AppButton,
  },
  data() {
    return {
      activeActor: null,
      fields: [
        { key: "edit_delete", label: "Edit/Delete" },
        {
          key: "id",
          label: "ID",
        },
        {
          key: "name",
          label: "Name",
        },
      ],
      isDeletionWarnShown: false,
    };
  },
  computed: {
    actors() {
      return this.$store.state.actors.data;
    },
    isLoading() {
      return this.$store.state.actors.isLoading.fetching;
    },
    isDeleting() {
      return this.$store.state.actors.isLoading.deleting;
    },
  },
  created() {
    this.fetchActors();
  },
  methods: {
    openActorForm(item = null) {
      if (item) this.activeActor = item;
      this.$root.$emit("bv::show::modal", "actor-form");
    },
    hideActorForm() {
      this.activeActor = null;
    },
    fetchActors() {
      this.$store.dispatch("actors/fetchData");
    },
    openDeletionWarning(item) {
      this.isDeletionWarnShown = true;
      this.activeActor = item;
    },
    cancelDeletionHandler() {
      this.isDeletionWarnShown = false;
      this.activeActor = null;
    },
    deleteActor() {
      this.$store.dispatch("actors/deleteData", this.activeActor).then(() => {
        this.activeActor = null;
        this.isDeletionWarnShown = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.mi-end-2 {
  margin-inline-end: 1rem;
}
</style>
