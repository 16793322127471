<template>
  <AppForm
    :id="id"
    ref="actorForm"
    class="p-1"
    :title="
      actor
        ? $t('edit_form', { name: actor.name }) || `Edit ${actor.name}`
        : $t('add_new_actor') || 'Add New Actor'
    "
    :errors-bag="errorsBag"
    :success-message="successMessage"
    :is-loading="isLoading"
    @hide="hideForm"
    @submit.prevent="handleSubmit"
  >
    <AppInput
      v-model="actorData['name']"
      type="text"
      required
      :label="$t('actor') || 'Actor'"
      rules="required"
    />
  </AppForm>
</template>

<script>
import { AppInput, AppForm } from "@/components/form/index";

export default {
  components: {
    AppInput,
    AppForm,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    actor: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      actorData: {},
      successMessage: null,
    };
  },
  computed: {
    isLoading() {
      return (
        this.$store.state.actors.isLoading.creating ||
        this.$store.state.actors.isLoading.updating
      );
    },
    errorsBag() {
      return this.$store.state.actors.errors;
    },
  },
  watch: {
    actor: {
      handler(val) {
        if (!val) {
          this.actorData = {};
          return;
        }
        this.actorData = { ...val };
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    handleSubmit() {
      const actionName = this.actor ? "updateData" : "createData";

      this.$store.dispatch(`actors/${actionName}`, this.actorData).then(() => {
        this.successMessage =
          this.$t("actor_has_been_saved") || "Actor has been saved";
        setTimeout(() => {
          this.successMessage = null;
        }, 1000);
        this.hideForm();
      });
    },
    hideForm() {
      this.$root.$emit("bv::hide::modal", this.id);
      this.$emit("hide");
      this.actorData = {};
    },
  },
};
</script>

<style lang="scss" scoped>
.mi-end-2 {
  margin-inline-end: 1rem;
}
</style>
